import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Wahnheide: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Wahnheide,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Wahnheide"
          points="897.2,942 902,926 906,924 906,910 926,908 928,916 944,916 960,958 948,994 900,1002 896,946  "
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 909.3176 953.8419)"
        >
          <tspan x="0" y="0">
            Wahn-{' '}
          </tspan>
          <tspan x="0" y="14.4">
            heide
          </tspan>
        </text>
      </g>
    </>
  );
});
